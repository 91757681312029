import styled from '@emotion/styled'

import { mq } from '../../constants/breakpoints'

export const BreadcrumbsStyles = styled.div`
  @media print {
    display: none !important;
  }
  
  font-size: 0.9375rem;
  min-height: 50px;
  margin-left: 12px;

  ${mq.mobile} {
    font-size: 0.9rem;
    min-height: 25px;
  }
`

export const LeftButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 20px;

  button {
    padding: 0 20px;
  }
`
