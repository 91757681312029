import styled from '@emotion/styled'

import { mq } from '../../constants/breakpoints'

const darkGray = '#333333'
const fixedLogoBreakpoint = '1450px'
const rmTextBreakpointLarge = '1450px'
const rmTextBreakpointSmall = '1115px'

export const HeaderStyles = styled.header`
  @media print {
    display: none !important;
  }
`
export const DrawerMenuContainer = styled.div`
  flex: 1;
  ${mq.mobile} {
    flex: auto;
  }
`
export const TopHeader = styled.div`
  position: fixed;
  z-index: 20;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  background-color: ${({ theme }) => theme.headerBackgroundColor || darkGray};
  padding: 0 20px;
  font-size: 16px;
  align-content: space-between;
  border-bottom: ${({ theme }) => (theme.headerBorder ? `1px solid ${darkGray}` : 'none')};

  * {
    color: ${({ theme }) => theme.headerColor || 'white'};
  }

  ${mq.xs_mobile} {
    padding: 0 10px;
  }
`
export const MainMenu = styled.div`
  display: flex;
`
export const PasswordModalContent = styled.div`
  width: 400px;
  height: 100px;
`
export const SiteTitle = styled.div`
  display: flex;
`
export const ContactLink = styled.div`
  margin-right: 25px;
  cursor: pointer;
  span {
    display: inline-block;
    margin-left: 12px;
  }
  a {
    text-decoration: none;
  }
  ${mq.xs_mobile} {
    margin-right: 12px;
    span {
      margin-left: 0;
    }
  }
`
export const TopRightPart = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  p {
    margin: 0;
    font-size: 1rem;
    font-weight: normal;
    margin-left: 30px;
  }
`

// istanbul ignore next
export const BottomHeader = styled.div<{
  backgroundImage?: string
  borderColor?: string
  largeHeader: boolean
  hasText: boolean
}>`
  position: ${({ largeHeader }) => (largeHeader ? 'relative' : 'inherit')};
  display: flex;
  justify-content: space-between;
  height: ${({ largeHeader }) => (largeHeader ? '220px' : '160px')};
  border-bottom: 1px solid ${({ borderColor }) => borderColor ?? '#707070'};
  padding: ${({ largeHeader }) => (largeHeader ? '0 40px' : '60px 20px 0 0')};
  align-items: center;
  margin-top: ${({ largeHeader }) => (largeHeader ? '60px' : '0px')};
  background: ${({ backgroundImage, largeHeader }) =>
    backgroundImage && largeHeader ? `url(${backgroundImage}) center/cover` : 'none'};
  background-repeat: no-repeat;

  a {
    margin-left: 40px;
  }

  ${mq.tablet} {
    padding: ${({ largeHeader }) => (largeHeader ? '0 20px' : '60px 20px 0 0')};
  }
  ${mq.mobile} {
    padding: ${({ largeHeader }) => (largeHeader ? '0' : '60px 20px 0 0')};
  }

  ${mq.xs_mobile} {
    padding: ${({ largeHeader }) => (largeHeader ? '0' : '60px 20px 0 0')};

    &.bottom-header__verlinde {
      background-position: right;
      justify-content: ${({ largeHeader }) => (largeHeader ? 'center' : 'inherit')};

      a {
        align-self: ${({ largeHeader }) => (largeHeader ? 'flex-end' : 'inherit')};
      }
    }
  }

  &.bottom-header__rm {
    align-items: flex-start;

    a {
      flex: 1;
      margin-top: 20px;
    }

    @media (max-width: ${rmTextBreakpointLarge}) {
      flex-direction: ${({ largeHeader }) => (largeHeader ? 'column' : 'row')};
    }

    ${mq.mobile} {
      flex-direction: ${({ largeHeader }) => (largeHeader ? 'column' : 'row')};
    }

    ${mq.xs_mobile} {
      flex-direction: ${({ largeHeader }) => (largeHeader ? 'column' : 'row')};
    }
  }

  &.bottom-header__swf {
    a {
      flex: 1;
      align-self: ${({ largeHeader }) => (largeHeader ? 'flex-start' : 'inherit')};
    }

    ${mq.mobile} {
      flex-direction: ${({ largeHeader }) => (largeHeader ? 'column' : 'row')};

      a {
        margin: ${({ largeHeader }) => (largeHeader ? '0' : '40px')};
        align-self: ${({ largeHeader }) => (largeHeader ? 'center' : 'inherit')};
      }
    }

    ${mq.xs_mobile} {
      flex-direction: ${({ largeHeader }) => (largeHeader ? 'column' : 'row')};

      a {
        margin: ${({ largeHeader }) => (largeHeader ? '0' : '40px')};
        align-self: ${({ largeHeader }) => (largeHeader ? 'center' : 'inherit')};
      }
    }

    @media (min-width: ${fixedLogoBreakpoint}) {
      a {
        position: ${({ largeHeader }) => (largeHeader ? 'fixed' : 'inherit')};
        top: 60px;
      }
    }
  }
`
export const HeaderIconPlaceholder = styled.div`
  background-color: lightgrey;
  height: 45px;
  width: 120px;
`
export const BrandImage = styled.img`
  width: 100px;
  height: auto;
  flex: 0 0 auto; // IE11 compatibility
  object-fit: scale-down; // Firefox compatibility
`

// istanbul ignore next
export const BannerTextContainer = styled.div`
  display: flex;
  flex: ${({ theme }) => (theme.brand === 'rm' ? 'none' : 2)};
  padding-right: ${({ theme }) => (theme.brand === 'rm' ? '200px' : 0)};
  align-self: center;
  justify-self: center;
  align-items: center;
  justify-content: center;
  min-width: ${({ theme }) => (theme.brand === 'rm' ? '365px' : 'inherit')};
  max-width: ${({ theme }) => (theme.brand === 'rm' ? '1000px' : 'inherit')};
  color: ${({ theme }) => theme.dashboardBanner?.titleColor || 'inherit'};

  h1 {
    margin: 10px 0;
    font-weight: 600;
    font-size: ${({ theme }) => (theme.brand === 'swf' ? '4rem' : '2rem')};
  }
  span {
    font-weight: 600;
    font-size: 1.1em;
  }

  @media (max-width: ${rmTextBreakpointLarge}) {
    padding-left: ${({ theme }) => (theme.brand === 'rm' ? '20px' : 0)};
  }

  @media (max-width: ${rmTextBreakpointSmall}) {
    padding-left: 0;
    margin-left: ${({ theme }) => (theme.brand === 'rm' ? '40px' : 'inherit')};
  }

  ${mq.mobile} {
    flex: 2;
    padding-right: 0;
    padding: '0 15px';
    margin: 0;
  }

  ${mq.xs_mobile} {
    flex: 2;
    padding-right: 0;
    padding: '0 15px';
    margin: 0;
  }
`

export const FlexFiller = styled.div`
  flex: 1;

  @media (min-width: ${fixedLogoBreakpoint}) {
    flex: ${({ theme }) => (theme.brand === 'swf' ? '0' : '1')};
  }
`
